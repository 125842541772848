.sidebar-item-popup {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: red;
  width: 200px;
  z-index: 100000;
}

.collapsed-sidebar-item:hover .sidebar-item-popup {
  display: block;
}
