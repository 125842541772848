.title-row {
  font-weight: bold;
  background-color: #f2f0f0;
  font-size: 14px;
}

.title-row-dark {
  font-weight: bold;
  background-color: #161b22;
  font-size: 14px;
}
