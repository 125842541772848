.styleAboveTables {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  height: 300px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}
.styleRow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

@media (max-width: 800px) {
  .styleAboveTables {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 800px;
    height: 300px;
    justify-content: flex-start;
    align-items: center;
  }
  .styleRow {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }
}
